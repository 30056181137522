import Vue from 'vue'
import Vuex from 'vuex'
import search from './modules/search'
import info from './modules/info'
import banners from './modules/banners'
import adsPlaces from './modules/adsPlaces'
import promo from './modules/promo'
import cart from './modules/cart'
import dishes from './modules/dishes'
import auth from './modules/auth'
import feedback from './modules/feedback'
import addresses from './modules/addresses'
import cards from './modules/cards'
import cardsTips from './modules/cardsTips'
import login from './modules/login'
import partners from './modules/partners'
import orders from './modules/orders'
import favorites from './modules/favorites'
import * as style from './modules/style'
import * as entities from './modules/entities'
import * as restaurants from './modules/restaurants'
import * as grocers from './modules/grocers'
import * as defaults from './modules/defaults'
import * as settings from './modules/settings'
import remoteConfig from './modules/remoteConfig'
import surveys from './modules/surveys'
import demands from './modules/demands'
import onboarding from './modules/onboarding'

import landing from './modules/landing'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [],
    modules: {
        login,
        search,
        info,
        style,
        banners,
        adsPlaces,
        promo,
        cart,
        cardsTips,
        dishes,
        entities,
        restaurants,
        auth,
        feedback,
        cards,
        favorites,
        addresses,
        orders,
        partners,
        grocers,
        defaults,
        settings,
        remoteConfig,
        surveys,
        demands,
        landing,
        onboarding,
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {},
})

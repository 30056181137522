import axios from '../../plugins/axios-silent';

export default {
  namespaced: true,

  state: {
    onboarding: null,
    onboardings: [],
    stories: [],
  },

  mutations: {
    SET_ONBOARDING(state, payload) {
      state.onboarding = payload;
    },
    SET_ONBOARDINGS(state, payload) {
      state.onboardings = payload;
    },
    SET_STORIES(state, payload) {
      state.stories = payload;
    },
    CLEAR_ONBOARDING(state) {
      state.onboarding = null;
    },
    CLEAR_ONBOARDINGS(state) {
      state.onboardings = [];
    },
    CLEAR_STORIES(state) {
      state.stories = [];
    },
  },

  actions: {
    async fetchOnboardings({ commit }) {
      try {
        const { data } = await axios.get('/front/onboarding');

        commit('SET_ONBOARDINGS', data);
      } catch (error) {
        console.error('Ошибка при получении онбордингов:', error);
      }
    },

    async fetchOnboarding({ commit }, onboardingId) {
      try {
        const { data } = await axios.get(`/front/onboarding/${onboardingId}`);
        commit('SET_ONBOARDING', data);
      } catch (error) {
        console.error('Ошибка при получении онбординга:', error);
      }
    },

    async fetchStories({ commit }, onboardingId) {
      try {
        const { data } = await axios.get(`/front/onboarding/${onboardingId}/stories`);
        commit('SET_STORIES', data);
      } catch (error) {
        console.error('Ошибка при получении сторисов:', error);
      }
    },

    clearOnboarding({ commit }) {
      commit('CLEAR_ONBOARDING');
    },

    clearOnboardings({ commit }) {
      commit('CLEAR_ONBOARDINGS');
    },

    clearStories({ commit }) {
      commit('CLEAR_STORIES');
    },
  },
};
